
const Footer = () => {

    return (
        <footer>
            <div className='left'>
                <ul>
                <a href='https://write-and-shine.com/contact/'><li>Contact</li></a>
                <a href='https://write-and-shine.com/frequently-asked-questions/'><li>FAQs</li></a>
                <a href='https://write-and-shine.com/frequently-asked-questions#policy'><li>Privacy policy</li></a>
                </ul>
            </div>
            <div className='centre'></div>
            <div className='right'>
                <p className='sub'>Each season we commission artists and illustrators we love to create work that captures seasonal changes and the themes of our programme. </p>
                <p className='sub'>The artwork for our Summer Festival is by the wonderful Alice Ferns!</p>
                <p className='sub'>(Our plus members also receive them as special giclée prints!)</p>
            </div>
        </footer>
    )

}

export default Footer