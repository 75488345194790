import { useState, useEffect } from 'react'
import { MdArrowForwardIos } from 'react-icons/md';


const Notebook = () => {


  let payLink = 'https://buy.stripe.com/cN2eXj4beexi0JaeV8';

  const soldout = false;
  const [imageTop, setImageTop] = useState(0);
  const [isFirstCycleComplete, setIsFirstCycleComplete] = useState(false);


  useEffect(() => {

    const updateImageTop = () => {
      setImageTop(prevImageTop => {
        if (prevImageTop === 4) {
          setIsFirstCycleComplete(true);
        }

        if (isFirstCycleComplete && prevImageTop === 0) {
          clearInterval(intervalId);
          return prevImageTop;
        }

        return (prevImageTop + 1) % 5;
      });
    };

    const timeoutId = setTimeout(() => {
      intervalId = setInterval(updateImageTop, 500);
    }, 1000);

    let intervalId;

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [isFirstCycleComplete]);


    return (
         <>
      <div className='banner'></div>
      <section className='line'>
         <div className='intro-split'>
         <h2 className='center'>Our notebooks!</h2>
          <div className='split'>
           <div className='left'>
            
            <div className={`notebook-img-${imageTop}`}></div>
            
            <div className='bottom-right' onClick={()=>
              {
                imageTop <4 ? setImageTop(imageTop+1)
                :
                setImageTop(0)
              }
              }>
              <p className='sub'>{imageTop+1}/5</p>
              <MdArrowForwardIos size={30} color={'#413d45'} />
            </div>
           </div>
           <div className='right'> 
                <p>We've collaborated with the lovely people at Before Breakfast to create our first notebook!</p>
                <p>Our Sunshine Yellow edition is hand-made in London and is just a little smaller than A5 size with lined paper. It's also good for use with fountain pens. Perfect for your morning pages!</p>
                <p>£31.99 + package &amp; posting</p>
              </div>
            </div>
              {
       soldout ?
        <button className='btncenter soldout'>Sold out</button>
      :
      <a href={payLink}><button className='btncenter book'>Buy now</button></a>
      }

        </div>
    </section>


    <section className='km'>
        <h3 className='center'>More about our notebooks</h3>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img nb-sm-3'></div>
            </div>
            <div className='right'>
            <p>This edition comes in our Sunshine Yellow, with Marmalade Orange on the inside covers. It has 140 pages of quality 120 GSM paper, providing plenty of space for creative wanderings and observations.</p>
            </div>
        </div>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img nb-sm-3'></div>
            </div>
            <div className='right'>
            <p>It's printed with soy-based ink and made from 100% post-consumer waste paper, completed with a Smyth Sewn Binding.</p>
            </div>
        </div>
        <div className='circles-wrap extra-margin'>
            <div className='left'>
            <div className='circle-km-shadow'></div>
            <div className='circle-km-img nb-sm-3'></div>
            </div>
            <div className='right'>
            <p>Each notebook also comes with a set postcards of our specially commissioned illustrations inspired by summer time!</p>
            </div>
        </div>
        <a href={payLink}>
           <button className='btncenter book'>Buy now</button>
        </a>
        </section>

        <section className='overflow-cards'>
        <h4 className='center'>Summer postcard collection</h4>

          <div className='card-wrap'>
            <div className='landscape-card postcard-1'>
               <p>Created by Alice Ferns</p>
            </div>
            <div className='landscape-card postcard-0'>
               <p>Janeen Constantino</p>

            </div>
            <div className='landscape-card postcard-2'>
                <p>Monica Kamakura</p>
            </div>
            <div className='landscape-card postcard-3'>
               <p>Marina Ester Castaldo</p>
            </div>
          </div>

        </section>

    
        <section className='km'>
      <h3 className='center'>Join us this summer!</h3>
      <div className='circles-wrap'>
        <div className='left'>
        <div className='circle-km-shadow'></div>
          <div className='circle-km-img img-0'></div>
        </div>
        <div className='right'>
        <p>This August we'll host our Summer Festival &ndash; a feast of creativity, with a delicious programme focusing on writing, food, memory and taste.</p>
        </div>
      </div>
      <a href='https://booking.write-and-shine.com'>
        <button className='btncenter book'>Find out more</button>
      </a>
    </section>


   
   </>
    )
}

export default Notebook