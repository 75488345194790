const Header = () => {
    return (
        <header>
            <a href='https://write-and-shine.com/'>
                <div className='logo'></div>
            </a>
            <ul>
                <li><a href='https://booking.write-and-shine.com/'>Workshops</a></li>
                <li><a href='https://write-and-shine.com/online'>Online library</a></li>
               <li> <a href='https://membership.write-and-shine.com/'>Membership</a></li>
            </ul>
        </header>
    )
}

export default Header 